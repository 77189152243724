<template>
  <div>
    <div class="sctp-mar-b15 sctp-container">
      <demand-detail :id="id"></demand-detail>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'coreRequireView',
    components: {
      demandDetail: () => import('@PAGE/users-center-manage/requirement-manage/ViewDetail')
    },
    data() {
      return {}
    },
    props: ['id'],
    methods: {},
    beforeMount() {
    }
  }
</script>

<style scoped>
</style>
